<template>
  <div class="edit-rule">
    <breadcrumb :breadcrumbList="editBreadcrumbList"></breadcrumb>
    <div class="edit-rule-content">
      <rule-form :ruleInfo='currentRuleInfo'></rule-form>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { editBreadcrumbList } from '../../config'
import RuleForm from './cpns/rule-form/rule-form.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import cache from '@/utils/cache'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    RuleForm
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const currentRuleInfo = ref({})
    const currentRuleId = computed(() => route.query.id)

    watch(currentRuleId, (n) => {
      // console.log(n);
      currentRuleInfo.value = cache.getCache('ruleInfo')

    }, {
      immediate: true,
      deep: true
    })



    return {
      editBreadcrumbList,
      COMPANY_ID,
      currentRuleInfo,
      currentRuleId
    }

  }
})
</script>

<style scoped lang="less">
.edit-rule {
  .edit-rule-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




