<template>
  <div class="add-site">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="reserveRule" label-width="120px" class="ruleForm"
      :size="formSize">
      <el-form-item label="规格名称：" prop="ruleName">
        <el-input v-model="ruleForm.ruleName" />
      </el-form-item>

      <!-- <el-form-item label="选择门店：" prop="shopId">
        <div class="shop">
          <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店" size="large">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
        </div>
      </el-form-item> -->

      <el-form-item label="预约范围：" prop="startMinutes">
        <div class="scope">
          <el-form-item prop="startMinutes">
            <el-input v-model="ruleForm.startMinutes" />
          </el-form-item>
          分钟
          <span>到</span>
          <el-form-item prop="endMinutes">
            <el-input v-model="ruleForm.endMinutes" />
          </el-form-item>

          <span>天内的到店服务</span>
        </div>
      </el-form-item>

      <el-form-item label="项目占用时长：" prop="name">
        <div class="duration">
          <el-radio v-model="ruleForm.serveOccupyTimeRadio" label="0" size="large">按照项目时长计算占用</el-radio>
          <el-radio v-model="ruleForm.serveOccupyTimeRadio" label="1" size="large">
            <span>固定为</span>
            <el-input :disabled="ruleForm.serveOccupyTimeRadio == '0'" v-model="ruleForm.serveOccupyFlag" />
            <span>分钟</span>
          </el-radio>
        </div>
      </el-form-item>

      <el-form-item label="取消预约：">
        <div class="cancel-reserve">
          <el-radio v-model="ruleForm.cancelTimeLimitRadio" label="0" size="large">任意时间</el-radio>
          <el-radio v-model="ruleForm.cancelTimeLimitRadio" label="1" size="large">
            <span>预约到店时间前</span>
            <el-input :disabled="ruleForm.cancelTimeLimitRadio == '0'" v-model="ruleForm.cancelTimeLimit" />
            <span>分钟，客户需联系客服取消或修改预约单</span>
          </el-radio>

        </div>
      </el-form-item>

      <el-form-item label="预约超时取消：">
        <div class="time-out">
          <el-radio v-model="ruleForm.autoCancelRadio" label="0" size="large">否</el-radio>
          <el-radio v-model="ruleForm.autoCancelRadio" label="1" size="large">
            <span>超过客户预约到店时间</span>
            <el-input :disabled="ruleForm.autoCancelRadio == '0'" v-model="ruleForm.autoCancel" />
            <span>分钟未到店，自动取消预约</span>
          </el-radio>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button @click="handleSaveClick(ruleFormRef)" class="save-btn" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import { reserveRule } from '../../../../config'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    ruleInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const ruleForm = reactive({
      ruleName: '',
      companyId: COMPANY_ID,
      shopId: '',
      appWeekDays: "",
      appStartTime: "",
      appEndTime: "",
      startMinutes: "",
      endMinutes: "",
      serveOccupyFlag: "",
      timeCategory: '',
      serveOccupyTimeRadio: '',
      cancelTimeLimitRadio: '',
      cancelTimeLimit: '',
      autoCancel: '',
      autoCancelRadio: '',
      id: ''
    })

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    watch(() => props.ruleInfo, (n) => {
      if (n != null) {
        ruleForm.id = n.id
        ruleForm.ruleName = n.ruleName
        ruleForm.shopId = '0'
        ruleForm.appWeekDays = n.appWeekDays
        ruleForm.appStartTime = n.appStartTime
        ruleForm.appEndTime = n.appEndTime
        ruleForm.endMinutes = (n.endMinutes) / 1440
        ruleForm.startMinutes = n.startMinutes

        if (n.serveOccupyFlag == 0) {
          ruleForm.serveOccupyFlag = 0
          ruleForm.serveOccupyTimeRadio = '0'
        } else {
          ruleForm.serveOccupyTimeRadio = '1'
          ruleForm.serveOccupyFlag = n.serveOccupyFlag
        }

        if (n.cancelTimeLimit == 0) {
          ruleForm.cancelTimeLimit = 0
          ruleForm.cancelTimeLimitRadio = '0'
        } else {
          ruleForm.cancelTimeLimitRadio = '1'
          ruleForm.cancelTimeLimit = n.cancelTimeLimit
        }

        if (n.autoCancel == 0) {
          ruleForm.autoCancel = 0
          ruleForm.autoCancelRadio = '0'
        } else {
          ruleForm.autoCancelRadio = '1'
          ruleForm.autoCancel = n.autoCancel
        }

      }
    }, {
      immediate: true,
      deep: true
    })

    const shopList = computed(() => store.state.reserve.shopList)


    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          router.go(-1)
          store.dispatch('reserve/updateReserveRuleAction', { ...ruleForm })
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const ruleFormRef = ref()


    const initPage = () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
    }
    initPage()

    return {
      ruleForm,
      shopList,
      handleSaveClick,
      ruleFormRef,
      reserveRule

    }

  }
})
</script>

<style scoped lang="less">
.ruleForm {
  .online-reserve {
    &:deep(.el-input) {
      max-width: 120px !important;
      margin-left: 10px;
    }
  }

  &:deep(.el-form-item) {
    .el-input {
      max-width: 600px;
    }
  }

  .online-reserve {
    &:deep(.el-input) {
      max-width: 120px !important;
      margin-left: 10px;
    }
  }

  .shop {
    max-width: 600px;

    &:deep(.el-select) {
      width: 100%;
    }
  }

  .save-btn {
    width: 150px;
    height: 48px;
    margin-top: 10px;
  }

  .pause-reserve {
    .top {
      &:deep(.el-select) {
        max-width: 120px;
        margin: 0 10px;
      }

      &>span:last-child {
        color: rgba(42, 130, 228, 1);
        font-size: 14px;
      }
    }

    .center {
      color: rgba(166, 166, 166, 1);
      font-size: 12px;
    }
  }

  .time-out {
    &:deep(.el-input) {
      max-width: 80px;
      min-width: 60px;
      margin: 0 10px;
    }
  }

  .duration,
  .cancel-reserve {
    &:deep(.el-select) {
      max-width: 120px;
      margin: 0 10px;
    }
  }

  .duration {
    max-width: 600px;

    &:deep(.el-input) {
      max-width: 150px;
      margin: 0 10px;
    }
  }

  .cancel-reserve {
    display: flex;
    justify-content: space-between;
    max-width: 600px;

    &:deep(.el-input) {
      max-width: 100px;
      margin: 0 10px;
    }
  }

  .tips {

    .top,
    .bottom {
      display: flex;

      &:deep(.el-input) {
        max-width: 80px;
        min-width: 60px;
        margin: 0 10px;
      }
    }

    .bottom {
      margin-top: 10px;
    }
  }

  .time {
    display: flex;
    justify-content: space-between;
    max-width: 600px;

    span {
      margin: 0 10px;
    }

    &:deep(.el-input) {
      margin-right: 10px;
      max-width: 240px;
    }

    &:deep(.el-select) {
      max-width: 120px;
      margin-left: 10px;
    }
  }

  .scope {
    display: flex;
    max-width: 600px;
    justify-content: space-between;

    &:deep(.el-input) {
      max-width: 80px;
      min-width: 60px;
      margin-right: 10px;
    }

    &:deep(.el-select) {
      .el-input {
        max-width: 120px;
      }
    }

    span {
      margin-right: 10px;
    }
  }
}
</style>







